export default {
  messages: {
    en: {
      empty_un_evaluation_class: 'All filled out',
    },

    tw: {
      empty_un_evaluation_class: '課後評鑑全部填寫完成！',
    },
  },
};
