<template lang="pug">
  div
    template(v-if="classroomList.length > 0")
      .reg-un-evaluation
        div(
          v-for="classroomData in classroomList"
          :key="classroomData.class_applications"
        )
          vip-classroom(:show_consultant_img="false" :classroom_type="'evaluations'" :classroomData="classroomData")
        //- 目前使用不到
        //- b-pagination(v-model="currentPage" :total-rows="classroomPaination.last_page" :per-page="1" align="center" )
    template(v-else)
      .reg-evaluayion-all-finished {{ $t('empty_un_evaluation_class') }}
</template>

<script type="text/javascript">
import vipClassroom from '@/components/class/vipClassroom';
import i18n from './lang.js';
import lioshutanApi from '@/api';

export default {
  name: 'VipEvaluations',

  components: {
    vipClassroom,
  },

  i18n,

  data() {
    return {
      classroomList: {},

      // currentPage: 1,

      // classroomPaination: {},
    };
  },

  computed: {},

  // watch: {
  //   currentPage(next, current) {
  //     if (next !== this.classroomPaination.current_page) {
  //       this.getVipUnEvaluationsClass();
  //     }
  //   },
  // },

  created() {
    this.getVipUnEvaluationsClass();
  },

  methods: {
    async getVipUnEvaluationsClass(){
      const response = await lioshutanApi.vip.getUnEvaluationClass();
      this.classroomList = response.data;
      // this.classroomPaination = response.meta.pagination;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/class/evaluation";
</style>
